import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapLocationDot,
  faEnvelopeOpenText,
} from "@fortawesome/free-solid-svg-icons";

function Contact() {
  const iconStyle = {
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
  };

  return (
    <div id="Contact" className=" bg-contact white-bg p-28   max-h-[800px]  ">
      <div className="font-poppins font-bold text-3xl   text-blue-700">
        CONTACT
      </div>
      <div className="font-poppins font-semibold text-3xl mt-4  text-black-700">
        Find me here! 👇
      </div>
      <div className="flex  max_750:flex-col max_750:justify-between  min_1600:space-x-10 mt-10">
        <div className="flex ">
          <FontAwesomeIcon
            icon={faMapLocationDot}
            style={{
              boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
            }}
            className="text-custom border mt-10 rounded-full p-5 text-blue-600 transition duration-300"
          />
          <div className="mt-10 ml-6">
            <div className="font-poppins text-2xl bg-white font-bold text-slate-600 ">
              Location
            </div>
            <div className="font-poppins text-3xl bg-white  font-light text-slate-600 ">
              Toronto, Canada
            </div>
          </div>
        </div>

        <div className="flex max_750:ml-0 ml-6">
          <FontAwesomeIcon
            icon={faEnvelopeOpenText}
            style={iconStyle}
            className="text-custom border mt-10 rounded-full p-5 text-blue-600 transition duration-300"
          />

          <div className="mt-10 ml-6">
            <div className=" animate-pulse font-poppins text-2xl bg-white font-bold text-slate-600 ">
              Mail
            </div>
            <div className="animate-pulse font-poppins text-3xl  bg-white  font-light text-slate-600 ">
              <a href="mailto:someone@example.com">
                bhaveshgavali2022@gmail.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
