import React, { useState, useEffect } from "react";

function AnimatedLogo() {
  const [string, setString] = useState("");
  const [hover, setHover] = useState(true);

  const finalString = "BhaveshG.Dev";
  let i = 0;

  const [isFlickering, setIsFlickering] = useState(true);

  useEffect(() => {
    setString("B");
    setIsFlickering(true);
    const intervalId = setInterval(() => {
      if (i + 1 < finalString.length) {
        setString((prevString) => prevString + finalString[i]);
        i++;
      } else {
        clearInterval(intervalId);
        setIsFlickering(false); // Stop flickering when string animation is completed
      }
    }, 200);

    return () => {
      clearInterval(intervalId);
    };
  }, [hover, i]);

  useEffect(() => {
    if (!isFlickering) {
      // Flickering is stopped, you can perform any other action here
    }
  }, [isFlickering]);

  return (
    <div
      onMouseEnter={() => {
        setHover(!hover);
      }}
      className={`max_900:hidden bg-clip-text transition   ease-in ${
        isFlickering
          ? "text-transparent  bg-gradient-to-r from-purple-400 to-pink-600"
          : "text-nav text-secondary"
      }  font-poppins     text-5xl font-semibold text-nav my-3`}
    >
      {string}
      <span
        className={`bg-gradient-to-r ml-1 from-gray-900 to-gray-900 ${
          isFlickering
            ? "animate-flicker inline-block text-opacity-100"
            : "hidden text-opacity-0 "
        }`}
      >
        |
      </span>
    </div>
  );
}

export default AnimatedLogo;
