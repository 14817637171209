import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import AnimatedLogo from "./animatedLogo";

function Nav() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("Hero");

  useEffect(() => {
    const scrollElement = document.getElementById(activeLink);
    if (scrollElement) {
      scrollElement.scrollIntoView({ behavior: "smooth" });
    }
  }, [activeLink]);

  const handleNavLinkClick = (link) => {
    setActiveLink(link);
  };

  const handleHamburgerClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <nav
      className={`items-center w-full bg-primary shadow-md text-[1.7rem] h-auto left-0 fixed top-0 z-[5]`}
    >
      <div className="flex  flex-row items-center justify-between pr-10 pl-10">
        <div
          className={`flex items-center flex-grow justify-between pt-6 pb-6 max_900:${
            !isMenuOpen ? "flex" : "hidden"
          } justify-center`}
        >
          <AnimatedLogo />

          <div className="text-transparent hidden max_900:block width-1/2  bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 font-poppins text-5xl font-semibold text-secondary">
            BhaveshG.Dev
          </div>

          {/* Move hamburger icon to right-most */}
          <FontAwesomeIcon
            onClick={() => handleHamburgerClick()}
            icon={!isMenuOpen ? faBars : faXmark}
            className=" text-custom hover:text-secondary  text-accent  h-14 transform ease-in-out  duration-700 hidden max_900:block"
          />
        </div>
        <div className={`first flex max_900:hidden space-x-8 space-y-0 `}>
          <button
            className={`text-accent font-poppins text-3xl font-semibold hover:text-secondary transition-all duration-150 ease-in-out`}
            onClick={() => {
              handleNavLinkClick("Hero");
              handleHamburgerClick();
            }}
          >
            Home
          </button>

          <button
            className={`text-accent font-poppins text-3xl font-semibold hover:text-secondary transition-all duration-150 ease-in-out`}
            onClick={() => {
              handleNavLinkClick("About");
              handleHamburgerClick();
            }}
          >
            About
          </button>
          <button
            className={`text-accent font-poppins text-3xl font-semibold hover:text-secondary transition-all duration-150 ease-in-out`}
            onClick={() => {
              handleNavLinkClick("Project");
              handleHamburgerClick();
            }}
          >
            Projects
          </button>
          <button
            className={`text-accent font-poppins text-3xl font-semibold hover:text-secondary transition-all duration-150 ease-in-out`}
            onClick={() => {
              handleNavLinkClick("Contact");
              handleHamburgerClick();
            }}
          >
            Contact
          </button>
        </div>
      </div>

      <div
        className={`slide-from-right   second max_900:${
          isMenuOpen ? "flex" : "hidden"
        } hidden justify-center items-center space-y-20  max_900:bg-p.rimary  z-10 h-[100vh] w-screen relative   flex-col`}
      >
        <FontAwesomeIcon
          onClick={() => handleHamburgerClick()}
          icon={faXmark}
          className="text-custom animate-spin hover:text-secondary absolute h-12 top-6 right-[4rem]  text-accent   transform ease-in-out  duration-700 hidden max_900:block"
        />
        <button
          className={`text-accent text-5xl font-poppins font-semibold hover:text-secondary transition-all duration-150 ease-in-out`}
          onClick={() => {
            handleNavLinkClick("Hero");
            handleHamburgerClick();
          }}
        >
          Home
        </button>
        <button
          className={`text-accent text-5xl font-poppins font-semibold hover:text-secondary transition-all duration-150  ease-in-out`}
          onClick={() => {
            handleNavLinkClick("About");
            handleHamburgerClick();
          }}
        >
          About
        </button>
        <button
          className={`text-accent text-5xl font-poppins font-semibold hover:text-secondary transition-all duration-150 ease-in-out `}
          onClick={() => {
            handleNavLinkClick("Project");
            handleHamburgerClick();
          }}
        >
          Projects
        </button>
        <button
          className={`text-accent text-5xl font-poppins font-semibold hover:text-secondary transition-all duration-150  ease-in-out`}
          onClick={() => {
            handleNavLinkClick("Contact");
            handleHamburgerClick();
          }}
        >
          Contact
        </button>
      </div>
    </nav>
  );
}

export default Nav;
