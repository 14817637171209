import React from "react";
// import RotatingText from "./rotatingText";

function About() {
  return (
    <section
      className="about flex max-h-fit justify-center text-center items-center min-h-screen bg-about py-60 px-0 max_1020:py-32 max_1020:px-30"
      id="About"
    >
      <div className="container my-0 mx-auto max-w-[107rem] py-0   max_460:py-0 max_460:px-[1.7rem]">
        <div className="about-content space-x-20 items-center grid  max_1280:flex max_1280:flex-col max_1280:items-center max_1280:justify-center grid-cols-2">
          <div className="img-side relative   ">
            <img
              src="https://blog.hubspot.com/hs-fs/hubfs/about-us-page_20.webp?width=893&height=600&name=about-us-page_20.webp"
              alt="mee"
              className="img-side__main-img rounded-[1.7rem] mx-auto  w-[55rem] h-[40rem]  max_1020:mb-[3.5rem] max_460:h-auto max_460:w-[60%]  max_900:h-[60%] max_1280:w-[80%]"
              draggable="false"
            />
          </div>
          <div className="text-side pr-6 text-start    max_1020:my-0 max_1020:mx-auto max_1020:max-w-[54rem] max_400:pr-0 max_1280:pt-10 max_1280:items-center max_1280:flex max_1280:flex-col max_1280:max-w-[65rem] after:first:max_1280:space-y-20 max_1280:leading-[2] max_1280:justify-center max_1280:text-center max_1280: ">
            <h3 className="text-blue-600 text-5xl font-bold mb-4 uppercase">
              About me
            </h3>
            <h4 className="text-primary font-poppins text-4xl mb-8 ">
              A dedicated Full-Stack Developer <br /> based in Toronto, Canada
            </h4>

            <p className="text-gray-600 text-3xl font-medium leading-normal font-mulish">
              I specialize in creating modern web applications using
              technologies like HTML, CSS, Tailwind, JavaScript, the MERN stack,
              TypeScript and others. My goal is to build user-friendly,
              value-adding, and scalable websites that leave a lasting impact.
            </p>
            <p className="text-gray-600 text-3xl font-medium leading-normal font-mulish">
              I love tackling problems and working collaboratively with teams to
              deliver top-notch solutions.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
