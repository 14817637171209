import React from "react";

import project from "../data/projects";
import ProjectCard from "./ProjectCard_v3";

function Projects() {
  return (
    <div id="Project" className=" py-20 bg-body bg-projects  space-y-10">
      <div className="font-mulish  px-7  pt-20 text-center  text-6xl text-gray-700 font-bold text-nav max_1020:ml-auto max_1020:mr-auto  ">
        PORTFOLIO
      </div>

      <div className="font-mulish  px-6 text-4xl font-bold text-accent text-center  text-nav max_1020:ml-auto max_1020:mr-auto">
        Every project presented a unique opportunity for me to excell my
        existing skills and aquire new ones.
      </div>
      <div className="flex  flex-col justify-center text-center items-center font-poppins text-3xl  mx-auto font-medium space-y-40 pt-14 text-accent">
        {project.map((project, index) => (
          <ProjectCard
            key={index}
            card={project}
            reversedLayout={index % 2 === 0 ? true : false}
          />
        ))}
      </div>
    </div>
  );
}

export default Projects;
