import React from "react";
import RoundText from "./_roundText";
import wave2 from "../wave2.png";

function Home() {
  return (
    <section
      id="home"
      className="hero   flex flex-col min-h-[100vh]  bg-home bg-gradient-to-br justify-between    relative w-fullm max_900:h-auto max_900:px-0 max_900:py-44   "
    >
      <div className="container my-0 mx-auto max-w-[107rem] py-0 px-16 max_460:py-0 max_460:px-[1.7rem]">
        <div className="content items-center flex gap-40 h-[65rem] justify-center relative max_900:flex-col max_900:gap-12 max_900:h-auto max_900:text-center min_1600:mt-24 min_1900:mt-40">
          <div className="hero-main flex items-center  gap-40 justify-center -space-x-10 relative max_900:flex-col-reverse max_900:gap-12 max_900:h-auto max_900:text-center]">
            <div className="hero-text flex flex-col max-w-[50rem] relative">
              <h1 className="text-primary font-mulish text-[5.5rem] leading-[1.2] mb-8 mt-8 max_500:text-[4rem]">
                Full-Stack Software Developer
              </h1>
              <img
                src={wave2}
                alt="waving_hand"
                className="h-24 absolute right-64 top-[8.7rem] w-24 max_900:right-12 max_500:h-[4.5rem] max_500:right-16 max_500:top-[6.4rem] max_500:w-[4.5rem] max_400:right-12 max_380:right-12 max_375:right-[1.7rem]"
              />
              <p className="text-[#555] font-mulish text-[1.8rem] font-medium leading-[1.6] max_900:mb-20">
                Hi, I'm Bhavesh Gavali. A passionate Full-Stack Developer based
                in Toronto, CCanada. 📍
              </p>
              <span className="cursor-pointer flex gap-[1.3rem] mx-0 my-[2.5rem] max_900:gap-[1.6rem] max_900:justify-center max_900:mb-16 max_900:-mt-8">
                <a
                  aria-label="linkedin"
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.linkedin.com/in/bhavesh-gavali-5b7a57192/"
                  className="  text-[3rem] transition-all duration-200 hover:text-[#147efb]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="tabler-icon tabler-icon-brand-linkedin"
                  >
                    <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                    <path d="M8 11l0 5"></path>
                    <path d="M8 8l0 .01"></path>
                    <path d="M12 16l0 -5"></path>
                    <path d="M16 16v-3a2 2 0 0 0 -4 0"></path>
                  </svg>
                </a>
                <a
                  aria-label="github"
                  rel="noreferrer"
                  target="_blank"
                  href="https://github.com/BhaveshG-22/"
                  className="text-nav text-[3rem] transition-all duration-200 hover:text-[#147efb]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="tabler-icon tabler-icon-brand-github"
                  >
                    <path d="M9 19c-4.3 1.4 -4.3 -2.5 -6 -3m12 5v-3.5c0 -1 .1 -1.4 -.5 -2c2.8 -.3 5.5 -1.4 5.5 -6a4.6 4.6 0 0 0 -1.3 -3.2a4.2 4.2 0 0 0 -.1 -3.2s-1.1 -.3 -3.5 1.3a12.3 12.3 0 0 0 -6.2 0c-2.4 -1.6 -3.5 -1.3 -3.5 -1.3a4.2 4.2 0 0 0 -.1 3.2a4.6 4.6 0 0 0 -1.3 3.2c0 4.6 2.7 5.7 5.5 6c-.6 .6 -.6 1.2 -.5 2v3.5"></path>
                  </svg>
                </a>
              </span>
            </div>

            <RoundText />
          </div>
          <div className="skills border-2 border-opacity-5 border-radius border-solid w-[100%] p-8 scroll-pb-px shadow-md items-center bottom-0 text-[#767676] flex text-[1.7rem] left-0 absolute max_900:flex-col max_900:static">
            <p className=" border-r-2  border-solid border-primary text-nav font-mulish font-semibold mr-28 pr-8 max_900:border-b-2 max_900:border-solid max_900:border-r-0 max_900:mb-12 max_900:mr-0 max_900:pb-4 max_900:pr-0">
              Tech Stack
            </p>
            <div className="logos">
              <ul className="flex gap-12 items-center justify-center list-none  max_900:justify-center ">
                <li className="cursor-pointer">
                  <img
                    src="https://skillicons.dev/icons?i=html,css"
                    alt="skill-icon"
                    className="h-20 transition-all duration-300 ease-in-out w-44  hover:-translate-y-4"
                    // className=" h-20 transition-all duration-300 ease-in-out w-44   hover:group-hover:-translate-y-4"
                  />
                </li>
                <li className="cursor-pointer">
                  <img
                    src="https://skillicons.dev/icons?i=js,ts"
                    alt="skill-icon"
                    className="h-20 transition-all duration-400 ease-in-out w-44  hover:-translate-y-4"
                  />
                </li>
                <li className="cursor-pointer">
                  <img
                    src="https://skillicons.dev/icons?i=react,next"
                    alt="skill-icon"
                    className="h-20 transition-all duration-300 ease-in-out w-44  hover:-translate-y-4"
                  />
                </li>
                <li className="cursor-pointer">
                  <img
                    src="https://skillicons.dev/icons?i=tailwind,scss"
                    alt="skill-icon"
                    className="h-20 transition-all duration-300 ease-in-out w-44  hover:-translate-y-4"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
