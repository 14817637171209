import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faGithub, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

function links() {
  return (
    <div className="flex justify-evenly p-10">
      <a
        href="https://www.linkedin.com/in/bhavesh-gavali-5b7a57192/"
        target="_blank"
        rel="noreferrer"
        className="scale-110"
      >
        <FontAwesomeIcon
          icon={faLinkedinIn}
          className="text-custom   rounded-full p-5 scale-150  text-white  "
        />
      </a>
      <a
        href="https://github.com/BhaveshG-22/"
        target="_blank"
        rel="noreferrer"
        className="scale-125 "
      >
        <FontAwesomeIcon
          icon={faGithub}
          className="text-custom   rounded-full p-5 scale-150  text-white  "
        />
      </a>
    </div>
  );
}

export default links;
