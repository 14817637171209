import React from "react";
import Home from "./components/home";
import Nav from "./components/nav";
// import About from "./components/about";
import About from "./components/v2about";
// import Projects from "./components/projects";
import Projects from "./components/projects_v3";
import Contact from "./components/contact";
import Links from "./components/links";

function App() {
  return (
    <div>
      <div className="flex justify-center" id="Hero">
        <Nav />
        <Home />
      </div>
      <About />
      <Projects />
      <Contact />
      <Links />
    </div>
  );
}

export default App;
